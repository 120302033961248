<template>
   <v-banner two-line class="mt-4 desaparecer" :color="coresModal[tipo]">
    <v-avatar
      slot="icon"
      :color="coresIcone[tipo]"
      size="40"
    >
    <i :class="icone[tipo]"></i>
    </v-avatar>
    {{texto}}
  </v-banner>
</template>

<script>
export default {
    name:"alertaFormulario",
    props: ['tipo', 'texto'],
    data: () => ({
        coresModal: ["red lighten-4", "green lighten-4"],
        coresIcone: ["red accent-1 accent-4", ""],
        icone: ["fas fa-exclamation white--text", "far fa-check-circle"]
    }),
}
</script>

<style>
    .desaparecer {
        animation: fadeOut ease 10s;
        opacity:0;
    }
    @keyframes fadeOut {
        0% {
            opacity:1;
        }
        100% {
            opacity:0;
        }
    }   
</style>
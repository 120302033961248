<template>
    <v-container>
        
        <alertaFormulario :tipo="mensagem.tipo" :texto="mensagem.texto" v-show="mensagem.tipo != 999" />
        
        <div class="loader" v-show="loader"></div>

        <h1 class="text-center my-12">Solicite seu orçamento</h1>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            width="10"
        >
            <v-text-field
            v-model="nome"
            :counter="100"
            :rules="nameRules"
            label="Nome"
            required
            ></v-text-field>

            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
            ></v-text-field>
            
            <v-text-field
                v-model="telefone"
                :counter="12"
                :rules="telefoneRules"
                label="Telefone Celular"
                required
            ></v-text-field>

            <v-select
            v-model="selecionarServico"
            :items="servicos"
            :rules="[v => !!v || 'Favor selecionar o serviço.']"
            label="Serviço"
            required
            ></v-select>

            <v-select
                v-model="selectTipo"
                :items="tipo"
                :rules="[v => !!v || 'Favor selecionar o tipo.']"
                label="Tipo"
                required
            ></v-select>

            <v-text-field
              v-model="descricao"
              :counter="150"
              label="Descrição"
            ></v-text-field>

            <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'Selecionar confirmar para enviar']"
                label="Confirmar ?"
                required
            ></v-checkbox>

            <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
            >
            Enviar
            </v-btn>
        </v-form>
        <div class="mt-12 text-center" fluid>
          <span>Formas de pagamento</span>
          <v-img 
            :src="require('@/assets/formas-pagamento.png')"
            width="50%"
            id="pagamento"
            class="mx-auto"
          >
          </v-img>
        
        </div>
    </v-container>
</template>
<script>

  import alertaFormulario from "./MensagemFormulario";

  export default {
    
    name: "Formulario",

    components: {
      alertaFormulario
    },
    data: () => ({
      valid: true,
      nome: '',
      nameRules: [
        v => !!v || 'O nome é obrigatório',
        v => (v && v.length <= 100) || 'O nome deve conter no máximo.',
      ],
      email: '',
      emailRules: [
        v => !!v || 'O e-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail digitado é invalido.',
      ],
      telefone: '',
      telefoneRules: [
        v => !!v || 'O telefone celular é obrigatório',
        v => /^(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})/.test(v) || 'Por favor, digitar telefone nesse formato, 99 99999-9999.',
      ],
      selecionarServico: null,
      servicos: [
        'Instalação de Concertina',
        'Instalação Cerca elétrica',
        'Instalação Câmeras',
      ],
      selectTipo: null,
      tipo: [
        'Residencial',
        'Empresarial',
      ],
      checkbox: false,
      descricao: '',
      retornoApi: 0,
      mensagem: {
        tipo: 999,
        texto: ""
      },
      loader: false
    }),

    methods: {

      validate() {
        if(this.$refs.form.validate()){
          
          var meuFormulario = `Nova solicitação de orçamento: 
          Nome: ${this.nome}
          Email: ${this.email} 
          Telefone: ${this.telefone}
          Serviço: ${this.servicos}
          Tipo Serviço: ${this.tipo}
          Descrição: ${this.descricao}`;
          
          var montandoRequisicao = {
            destinatarios: "efraim.santo@gmail.com, id7brasil@yahoo.com.br",
            conteudo: meuFormulario,
            autenticacao: process.env.VUE_APP_ENV_AUTENTICACAO,
          }

          var config = {
            method: "POST",
            body: JSON.stringify(montandoRequisicao),
            headers: { 'Content-Type': 'application/json' }
          };

          this.requisicao('https://edflex-api.herokuapp.com/enviar', config);
           
        }
      },

      reset () {
        this.$refs.form.reset()
      },

      resetValidation () {
        this.$refs.form.resetValidation()
      },

      async requisicao(url, config) {
        this.loader = true;
        await fetch(url, config)
          .then( response => {
            this.loader = false;
            this.retornoMensagem(response.status);  
          })
          .catch(erro => {
            this.loader = false;
            this.retornoMensagem(erro.status);
          });    
          this.reset();
      },

      retornoMensagem(retornoApi) {
        if(retornoApi == 200){
          this.mensagem.tipo = 1;
          this.mensagem.texto = "Sua solicitação foi enviada com sucesso!";
        }else{
          this.mensagem.tipo = 0;
          this.mensagem.texto = `Erro: ${retornoApi}, Algo de errado ocorreu no processo, favor nos contatar via telefone ou whatsapp (79) 98868-9723`;
        }
      }
    },
  }
</script>
<style scoped>

  .loader{
    z-index: 99;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 16px solid #e3e3e3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }
  .container{
    padding: 50px !important;
  }
  #pagamento{
    border-top: 1px solid #BDBDBD;
    border-radius: 4px;
  }
  @media screen and (max-width: 650px) {
    .container{
      padding: 30px !important;
    }
     h1{
        font-size: 1.7em;
      }
      .loader{
        left: 40% !important;
        transform: translate(-40%, -40%) !important;
      }
      #pagamento {
        width: 100% !important;
      }
  }
</style>
<template>
  <v-container id="img" fluid class="d-flex align-center justify-center">
    <h1 class="text-center color-white">Invista na segurança da sua casa <br /> e proteja sua família</h1>
  </v-container>
</template>

<script>
export default {
  name: "PainelPrincipal",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
    ],
  }),
};
</script>
<style scoped>
#img {
  height: 100vh;
  background: url("../assets/home.jpg")no-repeat 0% 50%;
  background-size: cover;
}
#img h1{
  text-transform: capitalize;
  color: white;
  background-color: hsl(0, 100%, 3%, 0.7);
  box-shadow: black 0.1em 0.1em 0.9em; 
  border-radius: 25px;
  padding: 40px 20px;
  font-size: 2vw;
  font-family: 'Krona One', sans-serif;
  text-shadow: black 0.1em 0.1em 0.2em;
}
@media screen and (max-width: 650px){
  #img h1{
    font-size: 5.5vw;
  }
}

</style>

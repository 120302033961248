<template>
  <v-app-bar app color="blue-grey darken-4" width="100%" dark>
    <div class="d-flex align-center">
      <h1>Edflex</h1>
    </div>

    <v-spacer></v-spacer>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on"> </v-app-bar-nav-icon>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title>
            <a :href="item.link">{{ item.title }}</a>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
   
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",
  data: () => ({
    items: [
      { title: "Inicio", link: "#home" },
      { title: "Quem Somos", link: "#sobre" },
      { title: "Serviços", link: "#catalogo" },
      { title: "Orçamento", link: "#orcamento" },
      { title: "Informaçoes", link: "#rodape" },
    ],
  }),
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: black !important;
}


</style>
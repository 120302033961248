<template>
    <v-container fluid class="quemSomos">
        <v-row id="teste">
            <v-col cols="12" sm="6">
                <h1 class="text-left" >Somos uma empresa especializada em segurança residencial e empresarial, 
                    focada em oferecer <span class="success--text">segurança</span> a um custo competitivo no mercado, sempre pensando no bem estar dos nossos clientes.
                    Um bem protegido oferece <span class="success--text">qualidade de vida</span> e sossego! 
                </h1>
            </v-col>
            <v-col cols="12" sm="6">
                <v-img 
                    src="../assets/imagem3.png"
                    width="40%"
                    class="alinharCentro"
                    contain
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name:"QuemSomos",
    data: () => ({
    
    }),
}
</script>
<style scoped>
#teste{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.quemSomos {
    height: 100%;
}
.quemSomos h1{
    border-left: 1px solid black;
    padding-left: 15px;
    margin-left: 20px;
    font-size: 1.9vw;
    text-align: left;
}
@media screen and (max-width: 650px){
    .quemSomos h1{
        font-size: 4vw;
        margin: 0 0 80px 15px;
    }
}

</style>
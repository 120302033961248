<template>
        <v-container class="altura" fluid>
            <h1 class="text-center my-12">Catálogo de serviços</h1>
            <v-col class="d-flex justify-space-around align-center servicos">
                <v-card
                    v-for="(item, i) in painelSeguranca"
                    :key="i"
                    max-width="344"
                    class="mx-auto"
                >
                    <v-img
                    :src= "item.imagem"
                    height="200px"
                    :position= item.posicao
                    >
                    </v-img>
                    <v-img
                        v-if="item.desconto"
                        width="80px"
                        :src= "item.desconto"
                        style="position: absolute; top: -8%; left: 85%"
                    >
                    </v-img>

                    <v-card-title>{{item.titulo}}</v-card-title>

                    <v-card-subtitle>{{item.subtitulo}}</v-card-subtitle>

                    <v-card-actions>
                    <v-btn
                        color="orange lighten-2"
                        @click="item.show = !item.show"
                        text
                    >
                        Mais Informações
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        icon
                        @click="item.show = !item.show"
                    >
                        <v-icon>{{ item.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                    <div v-show="item.show">
                        <v-divider></v-divider>
                        <v-card-text>
                            {{item.texto}}
                            <br><a href="#orcamento">Solicitar orçamento.</a>
                        </v-card-text>
                    </div>
                    </v-expand-transition>
                </v-card>
            </v-col>
         </v-container>
</template>
<script>
export default {
    name: "GridCards",
     data: () => ({
      show: false,
      painelSeguranca: [
          {
            imagem: require("@/assets/concertina.jpg"),
            posicao: "bottom",
            titulo: "Concertina",
            subtitulo: "Cercas Cortantes",
            texto: 'Instalação de cerca do tipo cortante com os tamanhos de 30 cm, 45 cm e 65 cm. Com duas opções de cerca: simples e dupla.',
            show: false,
            desconto: require("@/assets/desconto.png")
          },
          {
            imagem: require("@/assets/cerca-eletrica2.jpg"),
            posicao: "top",
            titulo: "Cerca Elétrica",
            subtitulo: "Cercas Eletrificadas",
            texto: 'Instalação acompanha kit completo: composto da central com alarme ou sirene, bateria 12v e placa de advertênvcia. Já as hastes podem variar entre o tipo M (simples) e o tipo industrial. O rolo de fio em aço, varia entre 0,30 mm a 1,5 mm.',
            show: false
          },
           {
            imagem: require("@/assets/camera.jpg"),
            posicao: "center",
            titulo: "Câmera",
            subtitulo: "Circuito de câmeras",
            texto: 'Circuito integrado de câmeras. Para mais informações entrar em contato.',
            show: false
          }
      ]
    }),
};
</script>
<style scoped>
    .altura {
        height: 100%;
    }
    .servicos{
        height: 80%;
    }
    @media screen and (max-width: 1000px){
        h1{
            font-size: 1.7em;
        }
        .servicos{
            height: 100% !important;
            display: inherit !important;
        }
        .v-card {
            margin: 50px auto;
        }
    }
</style>
<template>
  <v-app>
    <v-container fluid class="painel" id="home">
      <NavBar />
      <PainelPrincipal />
    </v-container>
    <v-container fluid class="painel" id="sobre">
      <QuemSomos />
    </v-container>
    <v-container  fluid class="painel grey lighten-2" id="catalogo">
      <div class="imgArame"></div>
      <GridCards />
    </v-container>
    <v-container fluid class="painel" id="orcamento">
       <div class="imgArame"></div>
       <Formulario />
    </v-container>
    <v-container fluid class="footer" id="rodape">
      <Footer />
    </v-container>
  </v-app>
</template>

<script>
import PainelPrincipal from "./components/PainelPrincipal";
import NavBar from "./components/NavBar";
import GridCards from "./components/GridCards";
import QuemSomos from "./components/QuemSomos";
import Formulario from "./components/MenuOrcamental";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    PainelPrincipal,
    NavBar,
    GridCards,
    QuemSomos,
    Formulario,
    Footer
  },
  data: () => ({ }),
};
</script>
<style>
  html, body{
    width: 100vw;
    min-height: 700px;
    overflow-y: scroll;
    padding: 0;
    margin: 0;
  }
  .imgArame{
    position: absolute;
    top: -38px;
    background: url("./assets/borda.png") repeat center;
    background-size: contain;
    width: 100%;
    height: 70px;
  }
  .painel {
    min-height: 100vh;
    padding: 0;
    position: relative;
    /* display: block; */
  }
  #linha{
    background-image: url("./assets/borda.png");
  }
  #sombra {
    background-image: linear-gradient(to left, #5F96C3 1%, white 25%); 
  }
  .footer{
    height: 30vh;
    background-color: #212121;
  }
  .alinharCentro{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  #catalogo{
    height: 110vh;
  }
  @media screen and (max-width: 1000px){
  #catalogo{
    height: 1700px !important;
  }
  .imgArame{
    top: -33px;
    height: 60px;
  }
   .footer{
    height: 50vh;
  }
}

</style>
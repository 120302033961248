<template>
  <footer dark>
    <div class="row mt-5 d-flex align-center justify-space-around white--text">
      <div>
        <h3>Edflex</h3>
        <ul>
          <li v-for="item in edflex" :key="item.id">
              <a :href="item.link">{{item.nome}}</a>
            </li>
        </ul>
      </div>
      <div>
        <h3>Informações</h3>
        <ul>
          <li v-for="item in informacoes" :key="item">{{item}}</li>
        </ul>
      </div>
      <div>
        <h3>Redes sociais</h3>
        <ul class="ulRedes">
          <li v-for="item in redes" :key="item.id">
            <a :href="item.link" target="_blank">
              <i :class="item.classe"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="referencia">
      <p class="text-center white--text pt-2">
        Site desenvolvido por
        <a
          href="https://www.linkedin.com/in/efraim-santos-b70337124/"
          target="_blank"
          >Efraim Santos <i class="fab fa-linkedin"></i
        ></a>
      </p>
    </div>
  </footer>
</template>
<script>
export default {
    name: "Footer",
    data: () => ({
        edflex: [
            {
                link: "#home",
                nome: "Home"
            },
            {
                link: "#sobre",
                nome: "Sobre"
            },
            {
                link: "#catalogo", 
                nome: "Catálogo de serviço"
            },
            {
                link: "#orcamento",
                nome: "Solicitar orçamento"
            }
        ],
        informacoes: ["Telefone: (79) 98836-1619", "Telefone: (79) 98832-4744", "Email: id7brasil@yahoo.com.br"]         ,
        redes: [
            {
                link: "https://api.whatsapp.com/send?phone=5579988324744&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es", 
                classe:"fab fa-whatsapp",
            },
            {
                link: "https://www.instagram.com/edflex_aju/",
                classe: "fab fa-instagram"
            }
        ]
    })    
}
</script>
<style scoped>
  .footer ul{
    padding: 0px !important;
  }
  .footer ul li {
    list-style: none;
    font-size: 15px;
    color: #BDBDBD !important;
  }

  .footer li a {
    text-decoration: none;
    color: #BDBDBD !important;
  }
  .ulRedes {
    padding: 0 !important;
  }
  .ulRedes li {
    font-size: 23px !important;
    text-align: center;
  }

  .referencia {
    margin-top: 3%;
    border-top: 1px solid #424242;
    font-size: 12px;
  }
  .referencia a {
    text-decoration: none;
  }
  @media screen and (max-width: 650px) {
    .footer {
      padding: 0 10px !important;
    }
    ul li {
      font-size: 13px !important;
    }
    .footer div:nth-child(3){
      margin: 15px 0 !important;
    }
    .ulRedes li {
      font-size: 22px !important;
    }
    footer div div:nth-child(1){
      padding-left: 15px;
    }
  }
</style>